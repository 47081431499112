.region-map-container {
  img {
    width: 100%;
    height: auto;
  }
}

.region-button-container {
  width: 100%;

  .btn-group {
    width: 100%;
  }

  .btn {
    font-size: 1.1em;
  }

  .hidden-sm-down .btn {
    width: 16.66%;
    padding: 5px;
    text-align: center;
  }

  .hidden-md-up .btn {
    width: 100%;
    padding: 5px;
    text-align: center;
  }

  .btn-group-vertical {
    width: 200px;
    max-width: 90%;
    margin: 0 auto;

    .btn {
      margin: 2px 0;
    }
  }


  .region-btn-orange {
    background-color: #DDA85C;
  }
  .region-btn-green {
    background-color: #8B9084;
  }
  .region-btn-blue {
    background-color: #7F92A0;
  }
  .region-btn-pumpkin {
    background-color: #A95539;
  }
  .region-btn-ltgreen {
    background-color: #879F83;
  }
  .region-btn-maroon {
    background-color: #80484C;
  }

}

.region-item-wrapper {
  padding: 0 !important;
  a { 
    text-decoration: none;
  }
}

.box-feature-wrapper {
  padding: 30px 0;
}

.region-hover-wrapper {
  opacity: 0; 
  transition: opacity .25s ease-in;

  &:hover {
    transition: opacity .25s ease-in;
    opacity: 1;
  }
}

.box-feature-header {
  text-align: center;
  padding: 0 !important;
  margin: 8px auto !important;

  width: 300px;
  height: 300px;
  @media screen and (min-width: 1200px) {
    width: 362px;
    height: 280px;
  }

  transition: filter .5s ease-in-out;
  filter: sepia(.5);
  &:hover {
    filter: sepia(0);
    transition: filter .5s ease-in-out;
  }

  hr {
    color: white;
    background-color: white;
    border: 0 !important;
    height: 2px;
    width: 200px;
  }
  h3 {
    font-size: 1.5em;
    text-transform: uppercase;
    color: white;
    margin: 0; 
  }
}
