
/*Archives Estate Styles*/
.verticle-image-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.estates-page > hr {
  background-color: #ddd;
  height: 1.5px;
}


.estates-page h1, .estates-page p {
  text-align: center;
}

.estates-page > h1 {
  padding-bottom: 0;
  margin-bottom: 0;
}

div.page-nav {
  text-align: left;
  padding-top: .7em;
}

.estate-list h1 {
  text-align: center;
}

div.estatesMenu {
  text-align: center;
}

div.estatesMenu ul {
  width: 100% !important;
}

div.estatesMenu li {
  width: calc(100% / 6);
  float: left;
  text-align: center;
  margin: 5px 0px !important;
  padding: 5px 0px !important;
}

div.estatesMenu a {
  color: white;
  display: block !important;
}

.estatesMenu li {
  cursor: pointer;
}


.estate-list button {
  margin-top: 3em;
}

.estate-box-content {
  display: none;
}

.estate-header {
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;
  background-color: black;
  padding: 2em;
  clear:left;
  height: 20em;
  text-align: center;
}

.estate-header img {
  max-width: 300px;
  max-height: 200px;
}

/*
.dark-overlay {
  background-color: grey !important;
  background-blend-mode: multiply;
}
*/

div.estate-header h1,  div.estate-header h2 {
  color: white;
  text-align: center;
}

/*Single Estate Styles*/

.estate-vintages{
  margin-right: 5em;
  margin-left: 5em;
}

.estate-news {
  padding: 3em 20%;
  background-size: cover;
}

.estate-news-box {
  height: 19em;
  text-align: center;
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;
  padding: 5%;
}

.estate-news-box h2, .estate-news-box p, .news-heading{
  color: white;
}

.news-heading {
  text-align: left;
}

.estate-name {
  font-size: 36pt;
  padding-bottom: .1em;
  margin-top: 1.5em;
}


/*Single Wine Styles*/

#website-link {
  float: right;
  margin-right: 15em;
}

.wine-container {
  padding-top: 0 !important;
}

.vintage-bottle {
  text-align: center;
}

.vintage-bottle img {
  height: 600px;
  @media screen and (max-width: 992px) { height: 400px; }
}

#accolade-section {
  margin-right: 5em;
  margin-left: 5em;
  margin-bottom: 3em;
}

.wine-bottle {
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: .3em;
  height: 30em;
  text-align: center;
  margin-bottom: 4em;
}
/*
.bottle-hover-content{
  background-color: rgba(50, 40, 30, .7);
  height: inherit;
  padding: .3em;
  display: none;
}

.bottle-hover-content h1, .bottle-hover-content h2, .bottle-hover-content h3 {
  text-align: center;
  color: white;
}

.bottle-hover-content h1 {
  font-size: 14pt;
  margin-top: 2em;
  padding-top: .3em;
  padding-bottom: .3em;
  border-top: solid;
  border-bottom: solid;
}

.bottle-hover-content h2 {
  font-size: 10pt;
  font-weight: normal;
  margin-top: 3em;
}

.bottle-hover-content h3 {
  font-weight: normal;
}

.bottle-hover-content button {
  margin-top: 3em;
}
*/
.slick-prev:before, .slick-next:before {
  color: #70655C;
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
}

