//
// REMEMBER: Bootstrap 4 is different!!!
//

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #6d5a68;

$font-family-sans-serif: mr-eaves-sans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      adobe-garamond-pro, Times, serif !default;


$btn-secondary-color: #fff;
$btn-secondary-bg: $brand-primary;
$btn-secondary-border: transparent;

