/* useful constants */
$letter-spacing: 0.08em;
$folio-gray: #909090;

/* General page styles */
div.header-margin {
  padding-top: 48px;
}
.zero-edges {
  margin: 0 !important;
  padding: 0 !important;
}

.hidden {
  display: none;
}


/* FROM TRENT.SCSS */
p {
  font-family: $font-family-serif;
}

ul#main-menu-container > li > a {
  &:hover {
    border-bottom: 2px solid white;
    padding-bottom: 0;
  }
}

ul#main-menu-container {
  li a:hover {
    color: white;
  }
}
/* END FROM TRENT.SCSS */

/* bootstrap 4 overrides */
.btn {
  cursor: pointer;
}
/* end bootstrap 4 overrides */

/* Custom page template styles */

.page-template-template-custom {
  .page-header { display: none; }
}

.landing-page-content {
  @media screen and (min-width: 1200px) { padding-right: 2rem; }
  @media screen and (max-width: 991px) { margin-top: 18px; }
  padding-top: 18px;
  text-align: center;
  h1, h2, h3, h4, h5, h6 {
    color: $brand-primary;
  }
  h1, h2 {
    text-transform: uppercase;
  }
  h1 {
    font-weight: 700;
    border-bottom: 2px solid lightgray;
    margin-bottom: 4px;
    padding-bottom: 6px;
    color: darken($brand-primary, 10%);
    letter-spacing: $letter-spacing;
    font-size: 36px;
    @media screen and (max-width: 1199px) { clear: left; }
  }
  h2 { 
    font-weight: 600;
    margin-top: 36px;
  }
  p, ol li, ul li {
    color: $folio-gray;
  }
  .alignleft { margin-right: 2rem; }
  .alignright { margin-left: 2rem; }
  img { 
    max-width: 100%; 
    margin-top: 1rem;
  }
  .blog-post-content {
    @media screen and (min-width: 1200px) { padding-right: 2rem; }
    @media screen and (max-width: 991px) { margin-top: 18px; }
    padding-top: 18px;
    h1, h2, h3, h4, h5, h6 {
      color: $brand-primary;
    }
    h1, h2 {
      text-transform: uppercase;
    }
    h1 {
      font-weight: 700;
      border-bottom: 2px solid lightgray;
      margin-bottom: 4px;
      padding-bottom: 6px;
      color: darken($brand-primary, 10%);
      @media screen and (max-width: 1199px) { clear: left; }
    }
    h2 {
      font-weight: 600;
      margin-top: 36px;
    }
    p, ol li, ul li {
      color: $folio-gray;
    }
    .alignleft { margin-right: 2rem; }
    .alignright { margin-left: 2rem; }
    img {
      max-width: 100%;
      margin-top: 1rem;
    }
  }
}

.home-bkg-imagebox-wrapper {
  p {
    width: 70%;
  }
}

/* Global Nav Styles */

$global-nav-item-width: 180px;
$global-nav-item-negative-padding: 12px;

.banner {
  background-color: #70655c;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.navbar { padding: 0; }

#main-header {
  background-color: #70655C !important;
  flex-direction: row;
}

.navbar-brand {
  padding-left: 14px;
}

#main-menu-search-container {
  .search-submit {
    display: none;
  }
  .search-field {
    border-radius: 0;
    height:24px;
    margin-top: 12px;
    width: 140px;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 0;
    padding-top: 0;
  }
}

ul#main-menu-container {
  @extend .navbar-nav;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: $letter-spacing;
  flex-direction: row;
  padding-top: 14px;
  ul, li { list-style-type: none; }
  ul.sub-menu { 
    min-height: 100%; 
    padding-top: 6px;
    li {
      padding-bottom: 6px;
    }
  }
  a { color: #EEE; text-decoration: none; cursor: pointer; }
  li {
    float: left;
    padding-left: 12px;
    width: $global-nav-item-width;
    text-transform: uppercase;
  }
  > li {
    > a {
      padding-bottom: 14px;
      padding-top: 0;
      float: left;
    }
    > ul.sub-menu {
      display: none;
      padding-left: 0;
      margin-left: -12px;
      li {
        float: left;
        clear: left;
      }
    }
    &:nth-of-type(1) {
      margin-left: 12px;
      /*@media screen and (min-width: 1199px) { margin-left: $global-nav-item-width; } */
      > ul.sub-menu { padding-left: ($global-nav-item-width - $global-nav-item-negative-padding + 14px); }
      /*@media screen and (min-width: 1199px) {> ul.sub-menu { padding-left: ($global-nav-item-width*2 - $global-nav-item-negative-padding); } }*/
      > ul.sub-menu ul.sub-menu { margin-left: ($global-nav-item-width - $global-nav-item-negative-padding); }
      > ul.sub-menu ul.sub-menu ul.sub-menu { margin-left: ($global-nav-item-width - $global-nav-item-negative-padding); }
    }
    &:nth-of-type(2) {
      > ul.sub-menu { padding-left: ($global-nav-item-width*2 - $global-nav-item-negative-padding + 14px);/* 528px;*/ }
      /*@media screen and (min-width: 1199px) {> ul.sub-menu { padding-left: ($global-nav-item-width*3 - $global-nav-item-negative-padding); } }*/
    }
    &:nth-of-type(3) {
      > ul.sub-menu { padding-left: ($global-nav-item-width*3 - $global-nav-item-negative-padding + 14px);/* 528px;*/ }
      /*@media screen and (min-width: 1199px) {> ul.sub-menu { padding-left: ($global-nav-item-width*4 - $global-nav-item-negative-padding); } }*/
    }
    &:nth-of-type(4) {
      > ul.sub-menu { padding-left: ($global-nav-item-width*4 - $global-nav-item-negative-padding + 14px);/* 528px;*/ }
      /*@media screen and (min-width: 1199px) {> ul.sub-menu { padding-left: ($global-nav-item-width*5 - $global-nav-item-negative-padding); } }*/
    }
    &:nth-of-type(5) {
      > ul.sub-menu { padding-left: ($global-nav-item-width*5 - $global-nav-item-negative-padding + 14px); }
      /*@media screen and (min-width: 1199px) {> ul.sub-menu { padding-left: ($global-nav-item-width*6 - $global-nav-item-negative-padding); } }*/
    }
    &:nth-of-type(6) {
      > ul.sub-menu { padding-left: ($global-nav-item-width*6 - $global-nav-item-negative-padding + 14px); }
      /*@media screen and (min-width: 1199px) {> ul.sub-menu { padding-left: ($global-nav-item-width*6 - $global-nav-item-negative-padding); } }*/
    }
    &:hover {
      > ul.sub-menu {
        /* this is the region-level submenu */
        display: block;
        background-color: #0e0e0e;
        opacity: .9;
        width: 100%;
        position: absolute;
        top: 48px;
        left: 0px;
        clear: both;
        margin: 0px;
        ul.sub-menu {
          display: none;
        }
        li:hover ul.sub-menu {
          /* this is the wine-level submenu */
          display: block;
          background-color: #0e0e0e;
          opacity: .9;
          position: absolute;
          top: 0;
          width: 100%;
          height: auto;
          padding-left: 0px;
          ul.sub-menu {
            display: none;
          }
          li:hover ul.sub-menu {
            /* this is the vintage-level submenu */
            position: absolute;
            top: 0;
            height: auto;
            padding-left: 0px;
            display: block;
            background-color: #0e0e0e;
            opacity: .9;
          }
        }
      }
    }
  }
}


/* Mobile global nav toggler and dropdown */

button.navbar-toggler {
  color: white;
  text-transform: uppercase;
  padding-top: 14px;
  font-size: 12px;
  letter-spacing: $letter-spacing;
  outline-color: none;
  outline-style: none;
  padding-top: 10px;
  span.exit {
    display: none;
    margin-left: -350px;
    margin-top: 38px;
    font-size: 36px;
    font-weight: 100;
    transform: scale(1, 0.75);
    color: black;
  }
  img.navbar-toggler-icon {
    height: 2em;
  }
  &[aria-expanded="true"] {
    span.text { visibility: hidden; }
    img.navbar-toggler-icon { visibility: hidden; }
    span.exit { display: inline-block; }
  }
}

.no-transition {
  -webkit-transition: height 0.01s;
  -moz-transition: height 0.01s;
  -ms-transition: height 0.01s;
  -o-transition: height 0.01s;
  transition: height 0.01s;
}

.full-height.collapsing {
  height: 100vh !important;
}

#mobile-global-nav {
  position: fixed;
  z-index: 1;
  right: 0;
  width: 250px;
  background-color: #312727;
  opacity: 0.9;
  height: 100vh;
  text-transform: uppercase;
  padding: 14px;
  font-size: 14px;
  letter-spacing: $letter-spacing;
  margin-top: 48px;
  padding-top: 42px;
  &.hidden { display: none; }
  &.collapsing {
    transition-duration: 0s;
  }
  a {
    color: white;
    font-weight: 200;
    &#back-to-menu {
      position: absolute;
      top: 12px;
      left: 0;
      padding-left: 14px;
      border-bottom: 1px solid #EEE;
      width: 100%;
      padding-bottom: 4px;
      img {
        height: 18px;
        vertical-align: top;
      }
    }
  }
  > ul {
    li {
      list-style-type: none;
      padding-bottom: 14px;
    }
  }
  .sub-menu {
    display: none;
    padding-left: 0;
  }
  #main-menu-container-mobile {
    margin-top: 4px;
  }
}

#mobile-nav-search-container {
  .search-submit {
    display: none;
  }
  .search-field {
    border-radius: 0;
  }
}


/* Blog Styles */

.blog {
  .slim-padding { padding-left: 5px; padding-right: 5px; }
  h1, h2 { letter-spacing: $letter-spacing; }
  @media only screen and (max-width: 767px) {
    h1 { font-size: 2rem; }
  }
}

.blog-nav {
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  padding-left: 15px;
  nav {
    ul {
      flex-direction: row;
      ul, li { list-style-type: none; }
      ul.sub-menu { 
        min-height: 100%; 
      }
      li {
        float: left;
        padding-left: 36px;
        text-transform: uppercase;
      }
      > li {
        > a {
          padding-bottom: 6px;
          padding-top: 6px;
          float: left;
        }
        > ul.sub-menu {
          display: none;
          padding-left: 0;
          margin-left: -12px;
          font-weight: 300;
          li {
            width: 100%;
            float: left;
            clear: left;
            padding-left: 14px;
            padding-right: 14px;
            border-bottom: 1px solid white;
            &:last-of-type { border-bottom: none; }
            a {
              color: white;
              width: 100%;
              &.right-arrow:after {
                content: ">";
                text-align: right;
                float: right;
              }
            }
          }
        }
        &:hover {
          > ul.sub-menu {
            /* this is the sub-brand or wine-level submenu */
            display: block;
            background-color: #807272;
            position: absolute;
            top: 33px;
            width: 350px;
            ul.sub-menu {
              display: none;
            }
            li:hover ul.sub-menu {
              /* this is the wine-level submenu, and only exists if there is a sub-brand */
              display: block;
              &.open-sub-menu { display: block; }
              background: transparent;
              top: 0;
              width: 100%;
              height: auto;
              margin-left: 336px;
              ul.sub-menu {
                display: none;
                &.open-sub-menu { display: block; }
              }
              > li {
                background-color: #a7a7a7;
              }
            }
          }
        }
      }

      padding-left: 0px;
      list-style-type: none;
      li {
        float: left;
        padding-left: 40px;
        &:first-of-type { padding-left: 0; }
        font-size: 14px;
        a {
          &:hover {
            font-weight: 600px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dropdown {
    margin-top: 20px;
    a {
      color: white;
      font-weight: 300;
    }
    .dropdown-menu {
      width: 100%;
      text-align: center;
      background-color: rgb(144, 135, 127);
      padding-top: 0;
      padding-bottom: 0;
    }
    #dropdownMenuButton {
      width: 100%;
      background-color: white;
      color: $brand-primary;
      border: 1px solid $brand-primary;
      border-radius: 0;
      text-align: left;
      &::after { 
        float: right; 
        font-size: 36px;
        margin-top: 4px;
        color: #BBB;
      }
    }
    #secondary-back-to-menu {
      display: block;
      &.hidden { display: none }
      text-align: left;
      width: 100%;
      border-bottom: 1px solid #CCC;
      height: 34px;
      padding-top: 4px;
      padding-left: 14px;
      img {
        text-align: left;
        height: 20px;
        margin-top: -3px;
      }
    }
    .secondary-menu-mobile {
      list-style-type: none;
      margin-bottom: 0;
      padding: 0;
      text-align: left;
      li { 
        border-bottom: 1px solid #CCC; 
        padding-top: 6px;
        padding-bottom: 4px;
        padding-left: 14px;
        &:hover { cursor: pointer; }
        &.hidden-parent {
          border-bottom: none;
          padding: 0;
        }
      }
      a {
        display: inline-block;
        width: 100%;
        &.right-arrow:after {
          content: ">";
          text-align: right;
          float: right;
          padding-right: 8px;
        }
      }
      li:last-of-type { 
        float: none; 
        border-bottom: none;
      }
      .sub-menu { 
        display: none; 
        list-style-type: none;
        padding-left: 0;
        li:last-of-type { 
          border-bottom: none;
        }
        .sub-menu {
          li:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.visit-website-icon { 
  display: none; 
  height: 14px;
  padding-left: 4px;
  margin-bottom: 6px;
}
.single-wine .blog-nav .secondary-menu-desktop > li:last-of-type { 
  float: right; 
  .visit-website-icon { display: inline; }
}
.single-estate .blog-nav .secondary-menu-desktop > li:last-of-type { 
  float: right; 
  .visit-website-icon { display: inline; }
}

.blog-categories {
  @media only screen and (min-width: 992px) { padding-top: 40px; }
  padding-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  h3 {
    font-weight: 700;
    color: darken($brand-primary, 10%);
  }
  ul {
    list-style-type: none;
    padding-left: 0px;
    font-size: 14px;
    li {
      padding-bottom: 2px;
      a {
        color: $folio-gray;
        &:hover {
          font-weight: 600px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.blog-home {
  p {
    color: $folio-gray;
    line-height: 1.8;
  }
  time { color: $folio-gray; }
  .featured-article {
    padding-top: 40px;
    padding-bottom: 40px;
    h1 {
      font-weight: 700;
      text-transform: uppercase;
      color: darken($brand-primary, 10%);
      border-bottom: 2px solid lightgray;
      margin-bottom: 4px;
      padding-bottom: 6px;
    }
    time {
      text-transform: uppercase;
      margin-bottom: 20px;
      display: block;
      color: $folio-gray;
    }
    .preview-image {
      display: block;
      height: 300px;
      @media only screen and (max-width: 991px) { height: auto; }
      overflow: hidden;
      margin-bottom: 28px;
      text-align: center;
      img {
        width: 100%;
        @media only screen and (min-width: 1200px) { height: 100%; width: auto; }
        @media only screen and (max-width: 1199px) { width: 100%; height: auto; }
      }
    }
    a.btn-secondary {
      color: #FFF;
      font-weight: 300;
      font-size: 1rem;
    }
  }
  .grid-article {
    @extend .slim-padding;
    display: block;
    margin-bottom: 24px;
    .preview-text {
      background-color: #EEE;
      margin-bottom: 24px;
      padding-left: 20px;
      padding-right: 20px;
    }
    a.btn-secondary {
      color: #FFF;
      font-weight: 300;
      font-size: 1rem;
    }
    h2 {
      font-weight: 700;
      font-size: 1.5em;
      height: 78px;
      @media screen and (max-width: 767px) { height: auto; }
      text-transform: uppercase;
      color: darken($brand-primary, 10%);
      margin-bottom: 0;
      padding-top: 10px;
    }
    time {
      text-transform: uppercase;
      display: block;
      margin-bottom: 14px;
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
    .preview-image {
      display: block;
      height: 150px;
      overflow: hidden;
      margin-bottom: 0;
      img { width: 100%; height: auto; }
    }
    .btn-wrapper {
      height: 60px;
    }
    .btn {
      display: block;
      max-width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.blog-post {
  @media screen and (min-width: 1200px) { padding-right: 2rem; }
  @media screen and (max-width: 991px) { margin-top: 18px; }
  padding-top: 18px;
  h1, h2, h3, h4, h5, h6 {
    color: $brand-primary;
  }
  h1, h2 {
    text-transform: uppercase;
  }
  h1 {
    font-weight: 700;
    border-bottom: 2px solid lightgray;
    margin-bottom: 4px;
    padding-bottom: 6px;
    color: darken($brand-primary, 10%);
    @media screen and (max-width: 1199px) { clear: left; }
    &:first-of-type { display: none; } //hides post title -- this way is not ideal! title should be filtered in PHP when generating post
  }
  h2 { 
    font-weight: 600;
    margin-top: 36px;
  }
  p, ol li, ul li {
    color: $folio-gray;
  }
  .alignleft { margin-right: 2rem; }
  .alignright { margin-left: 2rem; }
  img { 
    max-width: 100%; 
    margin-top: 1rem;
  }
  .blog-post-content {
    @media screen and (min-width: 1200px) { padding-right: 2rem; }
    @media screen and (max-width: 991px) { margin-top: 18px; }
    padding-top: 18px;
    h1, h2, h3, h4, h5, h6 {
      color: $brand-primary;
    }
    h1, h2 {
      text-transform: uppercase;
    }
    h1 {
      font-weight: 700;
      border-bottom: 2px solid lightgray;
      margin-bottom: 4px;
      padding-bottom: 6px;
      color: darken($brand-primary, 10%);
      @media screen and (max-width: 1199px) { clear: left; }
    }
    h2 {
      font-weight: 600;
      margin-top: 36px;
    }
    p, ol li, ul li {
      color: $folio-gray;
    }
    .alignleft { margin-right: 2rem; }
    .alignright { margin-left: 2rem; }
    img {
      max-width: 100%;
      margin-top: 1rem;
    }
  }
}




/* Style for the estates home page. */
/*
.estates-page {
  h1 {
    font-weight: 700;
    text-transform: uppercase;
    color: darken($brand-primary, 10%);
    border-bottom: 2px solid lightgray;
    margin-bottom: 4px;
    padding-bottom: 6px;
  }
}
*/



/* Style for the single wine page. */

.blog-nav {
  /* also for single estate page */
  @media screen and (min-width: 992px) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.wine-page {
  .wine-header {
    @extend .estate-header;
    /* remove top margin while secondary menu is hidden */
    margin-top: 32px;
  }
  h1 {
    color: darken($brand-primary, 10%);
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 36px;
    @media screen and (max-width: 767px) { font-size: 2em }
  }
  .tab-content {
    .panel a {
      -webkit-appearance: none;
    }
    .tab-pane {
      color: $folio-gray;
    }
    h2 {
      text-transform: uppercase; 
      font-size: 1.5em;
      font-weight: 500;
      color: $brand-primary;
      margin-bottom: .5rem;
      &:hover { cursor: pointer; }
      .wine-panel-down {
        float:right;
        margin-right: 6px;
        height: 26px;
      }
    }
    p {
      color: $folio-gray;
      line-height: 1.75em;
    }
  }
  h2.wine-subtitle {
    font-family: $font-family-serif;
    font-size: 1.5em;
    font-style: italic;
    margin-bottom: 1.5em;
    color: $folio-gray;
  }
  .download p {
    color: $folio-gray;
  }
  .asset-links .row {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 8px;
    font-size: 1rem;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: .95rem;
    }
    img { margin-right: 12px; }
    span {
      font-family: $font-family-sans-serif;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .view-more {
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 14px;
  }
  .nav-pills {
    padding-bottom: 1em;
    border-bottom: 1px solid $folio-gray;
    margin-bottom: 1em;
    li {
      margin-right: 18px;
    }
    a {
      color: $folio-gray;
      font-weight: 300;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 3px;
      &.active {
        background-color: $brand-primary;
        color: white;
      }
    }
  }
  .panel {
    padding-top: .5em;
    border-top: 1px solid $folio-gray;
    &:last-of-type { border-bottom: 1px solid $folio-gray; }
  }
  .panel-body {
    margin-bottom: 18px;
  }
  .vintage-bottle {
    margin-top: 32px;
  }
  .accolade-square { 
    margin-top: 24px;
    background-color: #DDD;
    @media screen and (min-width: 768px) { margin-top: 32px }
    p {
      color: #777;
      font-style: normal;
      line-height: 1.2em;
      padding-bottom: 14px;
    }
    h3, h4 { background-color: #EEE; }
    h3 {
      color: $brand-primary;
      font-size: 66px;
      padding-top: 12px;
    }
    h4 { 
      padding-bottom: 8px; 
      font-size: 20px;
      color: #777;
      font-weight: 600;
    }
    h5 {
      font-family: $font-family-serif;
      margin-bottom: 2px;
      color: #777;
    }
    h6 {
      font-family: $font-family-serif;
      color: #999;
    }
    .read-full {
      margin-top: 24px;
    }
  }
}



/* Style for single estate page */

.estate-header {
  /* remove top margin while secondary menu is hidden */
  margin-top: 32px;
}

.estate-body {
  margin-top: 32px;
  .thumbnail-image img {
    width: 100%;
  }
  h1 {
    color: darken($brand-primary, 10%);
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (max-width: 767px) { 
      font-size: 2em;
      margin-top: 18px;
    }
  }
  p {
    color: $folio-gray;
    line-height: 1.75em;
  }
}

.bottle-hover-content {
  text-align: center;
  padding: 0 !important;
  margin: 8px auto !important;
  background: rgba(0,0,0,.6);
  height: 100%;
  opacity: 0;
  transition: opacity .25s ease-in;
  &:hover {
    transition: opacity .25s ease-in;
    opacity: 1;
  }
  hr {
    color: white;
    background-color: white;
    border: 0 !important;
    height: 2px;
    width: 150px;
    margin-top: .4rem;
    margin-bottom: .4rem;
  }
  h3, h4, h5, h6 {
    color: white;
  }
  h3 {
    font-size: 1.25rem;
    text-transform: uppercase;
    margin: 0;
  }
  h4 {
    text-transform: uppercase;
    font-family: $font-family-serif;
    font-size: 1rem;
    margin-bottom: 12px;
  }
  h5 {
    font-family: $font-family-serif;
    font-size: 1.1rem;
    font-style: italic;
    margin-bottom: 0;
  }
  h6 {
    letter-spacing: .1rem;
  }
}

.slick-active {
  text-decoration: none !important;
}

#slider-arrows {
  text-align: center;
  .slick-arrow {
    position: absolute;
    margin-top: -300px;
    display: block;
    font-size: 20px;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 100%;
    border-style: none;
    height: 30px;
    width: 30px;
    padding: 0px;
    color:white;
    background-color: #a2a2a2;
  }
  .slick-prev { left: 0px; }
  .slick-next { right: 0px; }
}

.estate-news a:hover {
  text-decoration: none;
}

.estate-news hr {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.estate-news .news-heading {
  font-size: 2rem;
  color: white;
  font-weight: 500;
  @media screen and (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.estate-news .feature-box-content {
  opacity: 0;
  transition: opacity .25s ease-in;
}

.estate-box:hover {
  .feature-box-content {
    transition: opacity .25s ease-in;
    opacity: 1;
  }
}

.estate-news .feature-box-content {
  padding-top: 34px;
}

.estate-news .box-feature-header {
  width: 100%;
  filter: none;
  transition: none;
  &:hover {
    filter: none;
    transition: none;
  }
}

.estate-news-title {
  color: white;
  text-transform: uppercase;
  font-size: 1.25em;
  padding-left: 14px;
  padding-right: 14px;
}

.estate-news-excerpt {
  color: white;
  padding-left: 14px;
  padding-right: 14px;
  font-size: .9em;
}


/* style for trade & media assets on wine and vintage page */

.single-wine {
  .estate-news {
    padding: 3em;
  }
  .asset-box .entry-title {
    font-size: 18px;
  }
  .asset-thumbnail img {
    max-width: 100%;
  }
  button.brand-download {
    padding: .5rem .5rem;
    width: 100px;
    margin-left: 3px;
    margin-right: 3px;
    float: left;
  }
}

.estate-news .asset-box .row {
  margin-right: 0;
  margin-left: 0;
}

.estate-news .asset-box {
  height: auto;
  min-height: 200px;
}



/* Style for footer */

#footer { display: none; } //hide WordPress promotional footer

.folio-footer{
  text-transform: uppercase;
  margin-top: 40px;
}

.folio-footer ul{
  float: left;
  list-style: none;
  margin-right: 2em;
}

.folio-footer ul li{
  padding-bottom: 1em;
  font-size: 8pt;
}

.folio-footer ul li:first-of-type{
  font-weight: 600;
  color: darken($brand-primary, 10%);
}

.folio-sub-footer {
  text-align: center;
  margin-bottom: 14px;
}

.folio-sub-footer p {
  padding-bottom: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: $font-family-sans-serif;
  color: $folio-gray;
  font-size: 14px;
}


/* Search results, see also _search.scss */

.asset-box .entry-title {
  margin-top: 14px;
  font-size: 20px;
  text-transform: uppercase;
  color: $brand-primary;
  font-weight: 600;
}

button.brand-download {
  margin-bottom: 24px;
}

h5.search-headers {
  font-size: 22px;
  color: #777;
  letter-spacing: 2px;
}

h5.search-result-title {
  font-family: $font-family-serif;
  font-weight: 600;
  color: #777;
  font-size: 16px;
}

a.search-result-link {
  text-transform: uppercase;
  color: $folio-gray;
  font-size: 14px;
  text-decoration: underline;
}

.acclaim-accordion .panel-heading {
  font-family: $font-family-serif;
  color: #777;
  font-weight: 600;
  font-size: 16px;
}

.accolade-panel {
  .panel-body {
    padding-bottom: 10px;
  }
  .accolade-rating {
    font-style: italic;
    color: #777;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .accolade-source {
    font-style: italic;
    color: #777;
  }
  .accolade-review {
    font-weight: normal;
    color: $folio-gray;
    line-height: 2em;
    font-size: 14px;
    padding-bottom: 10px;
  }
}

.accolades-header {
  h3 {
    font-size: 1rem;
    color: $brand-primary;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.search-results-form-wrapper {
  margin: 30px;
  .search-field {
    width: 25em;
  }
}

@media only screen and (max-width: 767px) {
  .search-results-form-wrapper {
    margin-left: 5px;
    margin-right: 5px;
  }
  .search-results-form-wrapper .search-field {
    max-width: 90vw;
    margin-bottom: 10px;
  }
  .search-submit {
    margin-bottom: 10px;
  }
}


/* Signup, login, and user account update form styles */

.signup-form {
  color: #999 !important;
  margin-top: 36px;
  input {
    font-size: .9rem;
    letter-spacing: 1px;
  }
  label {
    margin-bottom: .1rem;
    font-family: $font-family-serif;
  }
  h3 {
    text-transform: uppercase;
    color: darken($brand-primary, 10%) !important;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .form-group {
    margin-bottom: .75rem;
  }
  .btn-secondary {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
  }
  a.terms {
    text-align: center;
    text-transform: uppercase;
    color: darken($brand-primary, 10%) !important;
    font-weight: 600;
    display: block;
    margin-top: 24px;
  }
  ::-webkit-input-placeholder {
    text-transform: uppercase !important;
    color: #999 !important;
    letter-spacing: 1px;
  }
  :-moz-placeholder {
    text-transform: uppercase !important;
    color: #999 !important;
    letter-spacing: 1px;
  }
  ::-moz-placeholder {
    text-transform: uppercase !important;
    color: #999 !important;
    letter-spacing: 1px;
  }
  :-ms-input-placeholder {
    text-transform: uppercase !important;
    color: #999 !important;
    letter-spacing: 1px;
  }
  ::-ms-input-placeholder {
    text-transform: uppercase !important;
    color: #999 !important;
    letter-spacing: 1px;
  }
}


/* Style for Sales Tool Generator saved presentations table */

.presentation-table {
  margin-top: 36px;
  h1 {
    text-transform: uppercase;
    font-size: 2.2rem;
    font-family: $font-family-sans-serif;
    color: darken($brand-primary, 10%);
    border-bottom: 2px solid #999;
    padding-bottom: 8px;
    letter-spacing: .2rem;
    font-weight: 600;
  }
  p {
    color: $folio-gray;
    padding-bottom: 14px;
  }
  h3 {
    color: darken($brand-primary, 10%);
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: 600;
  }
  table {
    width: 100%;
    border-top: 1px solid #999;
    margin-top: 22px;
    text-transform: uppercase;
  }
  th {
    padding-top: 12px;
    padding-bottom: 8px;
    color: darken($brand-primary, 10%);
    letter-spacing: 1px;
    text-align: center;
    &:first-of-type {
      text-align: left;
    }
  }
  td {
    padding-top: 6px;
    padding-bottom: 6px;
    border-top: 2px solid #d0d0d0;
    color: #909090;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    &:first-of-type {
      text-align: left;
      font-weight: 600;
    }
  }
  img.gear {
    max-height: 16px;
  }
  a.dropdown-toggle::after { display: none; }
  .dropdown-menu {
    left: -80px;
    min-width: 0;
    border-radius: 0;
    border: none;
    background-color: #918981;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 6px;
  }
  .dropdown-item {
    color: #EEE;
    border-bottom: 1px solid #EEE;
    padding-top: 5px;
    padding-bottom: 3px;
    &:hover {
      background-color: #C3BFB7;
    }
  }
  #create-presentation {
    display: inline-block;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: none;
    .dropdown-menu {
      left: 0;
    }
  }
}


/* Style for the sales tool generator saved presentations form */

#create-sales-tool-form {
}




/* ==========================================================================
   Tools
   ========================================================================== */
/* uncomment to see rogue margins, padding, etc. */
/*
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
}
*/
