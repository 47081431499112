.home-container {
  padding-bottom: 60px;
}

.home-header-banner {
  width: 100%;
  height: 500px;

  // background-image: url("http://foliowine.taui.cc/wp-content/uploads/2017/02/banner-castelgiocondo-vineyard-estate.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  text-align: center;

  img {
    width: auto;
    max-width: 80%;
    margin-top: 3em;
    max-height: 80%;
  }
}
.home-bkg-imagebox {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 9em;
  @media screen and (max-width: 1200px) { padding-top: 6em; }
  h3 {
    color: white;
    font-size: 2em;
    text-align: center;
  }
  hr {
    text-align: center;
    width: 125px;
    background-color: white;
    border: 0 !important;
    height: 1px;
  }
  p {
    font-size: .9em;
    font-weight: 300;
    color: white;
    text-align: center;
    width: 50%;
    margin: 0 auto;
  }
}
.home-bkg-imagebox-wrapper {
  margin: 0 !important;
  a {
    margin: 0 !important;
    padding: 2px !important;
    &:hover {
      text-decoration: none;
    }
  }
}

.home-sepia-transition {
  transition: filter .5s ease-in-out;
  filter: sepia(.5);
  &:hover {
  filter: sepia(0);
    transition: filter .5s ease-in-out;
  }
}


/*
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
}
*/
