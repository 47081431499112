

.search-field {
  margin-right: 5px;
}

.search-headers {
  font-weight: 600;
  color: #333;
  margin-top: 30px;
}

.search-results-page-header {
}
/*
.search-results-form-wrapper {
  margin: 30px;
  .search-field {
    width: 25em;
  }
}
*/

.search-result-wrapper {
  padding-bottom: 15px;
}

.search-result-title {
  font-size: 1.1em;
  font-style: italic;
  padding-bottom: 0;
  margin-bottom: 0;
}

.search-result-title-link {

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.search-result-link {
  font-weight: 300;
}


/* CSS From Divi /brand_asset search section */

.asset-thumbnail {
  height: 184px;
  width: 100%;
  background-color: white;
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
}

.asset-box {
  min-height: 200px;
  background-color: #eee;
  padding: 8px;
  margin: 4px 0;
}

@media (max-width: 580px) {
  .asset-box {
    height: auto;
  }
  .asset-thumbnail {
    margin: 15px auto;
  }
}

.asset-col {
  padding-right: 5px;
  padding-left: 5px;
}

.download h2, .search h2 {
  font-size: 16pt;
}

.download {
  margin-top: 2em;
}

.asset-box img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 184px;
  width: auto;
  margin-right: auto;
  margin-left: auto;
}

.asset-box-right {
  position: relative;
  min-height: 184px;
}

.asset-box-right h3 {
  text-align: center;
}

.asset-box-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.asset-type {
  font-style: italic;
  font-weight: bold;
  margin-bottom: 1em;
}

/* not needed */
/*
.asset-box-bottom a:nth-child(2) {
  float: left;
}

.asset-box-bottom a:nth-child(3) {
  float: right;
}

.asset-box-bottom a:nth-child(4) {
  float: left;
}

.asset-box-bottom a:nth-child(5) {
  float: right;
}
*/

.asset-box-bottom a, .asset-box-bottom button {
  // width: 107px;
}

.asset-description {
  font-style: italic;
  color: $folio-gray;
}

.brand-download-link {
  width: auto;
  height: auto;
}
.brand-download {
  margin: 10px;
  width: 107px;
}
/* end CSS from divi child /brand_asset search section */


/* css from divi child acclaim/accolade section */
/* Acclaim Archive */
.accolades-row {
  text-align: center;
  color:#70655C;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-appearance: inherit !important;
  appearance: inherit !important;
}

.accolades-row > div {
  border-top: 1px solid #ccc;
  padding: 15px 0;
}

.accolade-expand {
  padding: 0 !important;
  margin-left: 10px;
}

.accolades-header h3 {
  text-align: center;
}

.accolades-header div.col-xs-5 h3 {
  text-align: left;
}

.acclaim-border-bottom {
  border-width: 1px;
  border-bottom-style: solid;
  border-radius: 0;
  border-color: #ccc;
  display: flex;
}

.acclaim-border-bottom h3 {
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 0;
}

.acclaim-border-margin {
  margin-right:15px;
  margin-left: 15px;
}

.acclaim-subtitle {
  padding-bottom:50px !important;
  margin: 0 !important;
}

.accolade-expand {
  width: 1.5em;
  background-color: #ccc;
  color: white;
  text-align: center;
  padding-bottom:0.2em;
  float:left;
  cursor: pointer;
}
/*
div.push-left {
  float:left;
  margin-left: 0.5em;
  font-weight: bold;
}

div p.push-left {
  margin: 0 0 0 15px ;
  font-weight: bold;
  padding-bottom: 5px;
}
*/

/* end css from divi child acclaim/accolade section */

/* more css that was conserved from single wine page to acclaim/accolade */
.panel-body p {
  margin-bottom: 0;
}

.panel-heading h2 {
  margin-bottom: 0;
}

/* bootstrap hack: fix content width inside hidden tabs */
.search {
.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: block;    /* undo display:none          */
  height: 0;         /* height:0 is also invisible */ 
  overflow: hidden;  /* no-overflow                */
}
.tab-content > .active, .pill-content > .active {
  height: auto;      /* let the content decide it  */
} 
}/* bootstrap hack end */


.accolade-square {
  background-color: #eee;
  margin-right: 1em;
  padding: 0;
  text-align: center;
}

.accolade-square h3 {
  font-size: 36pt;
  font-weight: bold;
  padding-top: .3em;
  text-align: center;
  padding-bottom: 0;
  margin-bottom: 0;
}

.accolade-square h4 {
  text-transform: uppercase;
  text-align: center;
  font-size: 12pt;
  margin-top: 0;
}

.accolade-square h5 {
  font-style: italic;
  font-weight: bold;
  text-align: center;
  text-transform: none;
  padding-bottom: 0;
  color: #5F534B;
}

.accolade-square h6 {
  text-align: center;
  font-weight: normal;
  font-style: italic;
  text-transform: none;
  color: #5F534B;
}

.accolade-square .read-full {
  text-align: center;
  font-weight: normal;
  text-decoration: underline;
  text-transform: uppercase;
  color: #5F534B;
  font-size: 8pt;
  cursor: pointer;
}

.expanded-review {
  display: none;
}

.accolade-square .review {
  font-style: italic;
}

.accolade-square-bottom p {
  margin-bottom: 0;
}

.accolade-square-bottom button {
  margin-bottom: .5em;
}

.accolade-square-bottom button, .accolade-square-bottom a {
  width: 100%;
}

.accolade-square-bottom {
  background-color: #ddd;
  padding: .7em 1em !important;
  margin: 0;
}
/* john removed because it was breaking the style on wine / vintage pages
.wine-panels h2, h2.tasting-notes{
  margin-top: 0 !important;
  text-transform: uppercase;
  font-size: 13pt;
  padding-bottom: 0 !important;
}
*/

/* trent's shims to fix this stuff */
.buttons-container {
  margin-top: 30px;
  margin-bottom: 60px;
  float: right;
}
.accolade-panel {
}
.accolade-expand-title {
  font-weight: 700;
  /* important because they have weird stuff above */
  /* don't rock the boat!! */
  text-align: left !important;
}

/* end more css that was conserved from single wine page to acclaim/accolade */
