
.btn {
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 0.05em;
}

.btn-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
    border-radius: 3px;
    font-size: 0.9em;
    font-weight: 300;
}
